.rc-tooltip {
  opacity: 1;
}

.rc-tooltip.hide {
  display: none;
}

.rc-tooltip-arrow {
  border-bottom-color: #0075ea !important;
}

.tooltip-error .rc-tooltip-arrow {
  border-bottom-color: #ec3237 !important;
  border-width: 0 8px 8px;
  margin-left: -7px;
  top: 1px;
}

.tooltip-error .rc-tooltip-inner {
  display: flex;
  align-items: center;
  font-weight: 400;
  background-color: #ec3237 !important;
  border-radius: 4px;
  padding: 1.6rem 2.4rem;
  max-height: 40px;
}
