.edusynch-dropdown.primary {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  top: 55px !important;

  // arrow-up
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #0075ea;
    position: absolute;
    right: 11px;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);
  }

  &:hover {
    &::before {
      background: #003871;
    }

    .rc-dropdown-menu-item-active {
      background-color: #003871;
    }
  }

  ul {
    background: #0075ea;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

    > .rc-dropdown-menu-item {
      color: #fff;
      transition: background-color 0.2s ease;

      svg path {
        fill: white;
      }
    }
  }
}

.edusynch-dropdown {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;

  // arrow-up
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #dfe1e2;
    position: absolute;
    right: 11px;
    transition: background-color 0.2s ease;
    transform: rotate(45deg);
  }

  &:hover {
    &::before {
      background: #dfe1e2;
    }

    .rc-dropdown-menu-item-active {
      background-color: #dfe1e2;
    }
  }

  ul {
    background: #fff;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

    > .rc-dropdown-menu-item {
      color: #5d6670;
      transition: background-color 0.2s ease;
    }
  }
}
