// import config
@import '../config/config';

// ==========================================================================
// TYPOGRAPHY
// ==========================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

h1,
.alpha {
  @include font-size(48);
}

h2,
.beta {
  @include font-size(32);
}

h3,
.gamma {
  @include font-size(26);
}

h4,
.delta {
  @include font-size(20);
}

h5,
.zeta {
  @include font-size(18);
}

h6,
.eta {
  @include font-size(16);
}
