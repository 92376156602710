// import config
@import '../config/config';

// vars
$body--font-color: $color-quartenary;
$body--background-color: $color-octonary;

// ==========================================================================
// RESET
// ==========================================================================

* {
  @include box-sizing(border-box);
}

html {
  font-size: 62.5%;
  translate: no;
}

p {
  margin-top: 0;
  line-height: 1.6em;
}

a {
  outline: none !important;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
}
