.ct-toast-success {
  color: #1bd171 !important;
}

.ct-toast-warn {
  color: #fbab34 !important;
}

.ct-toast-info {
  color: #0075ea !important;
}

.ct-toast-error {
  color: #ec3237 !important;
}

.ct-text {
  color: #5d6670 !important;
  line-height: 16px !important;
}

.ct-heading {
  font-size: 1.6rem !important;
  line-height: 24px;
  font-weight: bold;
}

#ct-container {
  z-index: 9001;
}

.ct-toast {
  position: relative;
  padding: 16px 45px 16px 24px !important;
  min-width: 400px;
  justify-content: flex-start !important;
}

.ct-toast::after {
  content: '';
  width: 1.6rem;
  height: 1.6rem;
  background-image: url('assets/layout/close.svg');
  position: absolute;
  right: 25px;
}

.ct-toast-warn .edu-icon-loading {
  position: relative;
  left: 0;
  top: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

@media (max-width: 768px) {
  .ct-toast {
    min-width: 300px;
  }

  .ct-toast-warn .edu-icon-loading {
    visibility: hidden;
  }
}
