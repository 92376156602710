@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.maintenance-page {
  font-family: 'Roboto', sans-serif;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #f2f9ff 100%);
}

.maintenance-page div {
  width: 100%;
  max-width: 800px;
}

.maintenance-page .clouds {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(assets/layout/maintenance-clouds.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  max-width: 1587px;
  margin: auto;
}

@media (max-width: 780px) {
  .maintenance-page .clouds {
    background-image: url(assets/layout/maintenance-clouds-tablet.svg);
  }
}

@media (max-width: 500px) {
  .maintenance-page .clouds {
    background-image: url(assets/layout/maintenance-clouds-mobile.svg);
  }
}

.maintenance-page .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.maintenance-page .content img {
  width: 438px;
  height: 248px;
}

@media (max-width: 768px) {
  .maintenance-page .content img {
    width: 278px;
    height: 157px;
  }
}

.maintenance-page .title {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  line-height: 56px;
  color: #5d6670;
  width: 100%;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .maintenance-page .title {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 8px;
  }
}

.maintenance-page .description {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  width: 100%;
  color: #5d6670;
  margin-bottom: 56px;
}

@media (max-width: 768px) {
  .maintenance-page .description {
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
}

.maintenance-page .footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 24px 15px;
  font-size: 14px;
  color: #5d6670;
}
