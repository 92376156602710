// Import all elements
@import '../config/config';

/* ==========================================================================
  LOADING
  ========================================================================== */

.swal2-popup {
  font-size: 1.5rem;
}
