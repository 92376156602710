svg.edusynch-icon {
  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.left {
    margin-right: 0.4rem;
  }

  &.right {
    margin-left: 0.4rem;
  }

  &.cursor {
    cursor: pointer;
  }
}