// ==========================================================================
// FONTS
// ==========================================================================

// font-size
@mixin font-size($sizeValue: 13, $line-height: false) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 10) + rem;
  @if $line-height != false {
    line-height: $line-height + px;
    line-height: ($line-height / 10) + rem;
  }
}

// font
@mixin font(
  $style: normal,
  $weight: normal,
  $size: 13,
  $line-height: 13,
  $font-family: $baseFont
) {
  font-size: $size + px;
  font: $style $weight ($size / 10) + rem + '/' + $line-height $font-family;
}

// font-face
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.woff') format('woff');
    src: url('#{$file-path}.woff2') format('woff2');
    font-weight: $weight;
    font-style: $style;
  }
}
