// import config
@import '../config/config';
@import 'base-vars';

// ==========================================================================
// SCAFFOLDING
// ==========================================================================
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  @include font-size(14);
  box-sizing: border-box;
  color: $body--font-color;
  font-family: $font-base;
  -webkit-font-smoothing: antialiased;
}

.global {
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  min-height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.container {
  width: 90%;

  @media screen and (min-width: 768px) {
    width: 740px;
  }

  @media screen and (min-width: 1024px) {
    width: 970px;
  }

  @media screen and (min-width: 1200px) {
    width: 1170px;
  }

  @media screen and (min-width: 1430px) {
    width: 1312px;
  }
}

//
// MAIN
// ==========================================================================
.container-fluid {
  max-width: 1170px;
  margin: 0 auto;

  @media screen and (max-width: 767.98px) {
    padding-right: 5px;
    padding-left: 5px;
  }

  @media screen and (min-width: 1430px) {
    max-width: 1312px;
  }

  @media screen and (min-width: 1900px) {
    max-width: 1744px;
  }
}
