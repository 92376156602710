.MuiCalendarPicker-root div[role='presentation'] {
  border: 1px solid #e1e0e7;
  border-radius: 4px;
  padding: 5px 4px 5px 10px;
  color: #5d6670;
}

.MuiCalendarPicker-root div[role='presentation'] button {
  padding-left: 0;
  padding-right: 0;
}

.MuiCalendarPicker-root span.MuiTypography-caption {
  background-color: rgba(0, 117, 234, 0.1);
  color: #5d6670 !important;
  line-height: normal;
  height: 20px !important;
  margin: 7px 0 !important;
  padding: 0 20px;
}

.MuiCalendarPicker-root span.MuiTypography-caption:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.MuiCalendarPicker-root span.MuiTypography-caption:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.MuiCalendarPicker-root .MuiPickersDay-root {
  border-radius: 4px;
}

.MuiCalendarPicker-root .MuiPickersDay-root.MuiPickersDay-today,
.MuiCalendarPicker-root .MuiPickersDay-root:focus,
.MuiCalendarPicker-root .MuiPickersDay-root:hover {
  border-width: 0;
  background-color: transparent;
  color: #0075ea;
}

.MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected,
.MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected:focus {
  background-color: #0075ea;
  color: #ffffff;
}
