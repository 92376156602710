// ==========================================================================
// COLORS
// ==========================================================================

$color-primary: #ccc !default;
$color-secondary: #ccc !default;
$color-tertiary: #ccc !default;
$color-quartenary: #ccc !default;
$color-quinary: #ccc !default;
$color-senary: #ccc !default;
$color-septenary: #ccc !default;
$color-octonary: #ccc !default;
$color-nonary: #ccc !default;
$color-denary: #ccc !default;
$color-undenary: #ccc !default;
$color-duodenary: #ccc !default;
$color-tridenary: #ccc !default;

$color-default: $color-primary;
