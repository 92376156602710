// ==========================================================================
// BOX
// ==========================================================================

// box Shadow
@mixin box-shadow($shadow: 0 0 10px #000000) {
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

// box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  -ms-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}
