.rc-tooltip {
  opacity: 1;
}

.rc-tooltip.hide {
  display: none;
}

.rc-tooltip-arrow {
  border-top-color: #0075ea !important;
  border-bottom-color: #0075ea !important;
}

.tooltip-dark .rc-tooltip-arrow {
  border-bottom-color: #004285 !important;
  border-bottom-color: #004285 !important;
}

.tooltip-light .rc-tooltip-inner {
  display: flex;
  align-items: center;
  font-weight: 400;
  background-color: #ffffff !important;
  border-radius: 4px;
  padding: 1.6rem 2.4rem;
  min-height: 40px;
}

.tooltip-error .rc-tooltip-arrow {
  border-bottom-color: #ec3237 !important;
  border-width: 0 8px 8px;
  margin-left: -7px;
  right: 1rem;
  top: 1px;
}

.tooltip-error .rc-tooltip-inner {
  display: flex;
  align-items: center;
  font-weight: 400;
  background-color: #ec3237 !important;
  width: max-content;
  border-radius: 4px;
  padding: 1.6rem 2.4rem;
  max-height: 40px;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  transform: scale(1) !important;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  transform: scale(1) !important;
}

.rc-tooltip-content::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 8px;
  top: 10px;
  left: 50%;
  box-sizing: border-box;
  border: 5px solid black;
  border-color: transparent transparent #0075ea #0075ea;
  transform-origin: 0 0;
  transform: rotate(-225deg);
  display: block;
  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
}

.light .rc-tooltip-content::after {
  border-color: transparent transparent #fff #fff;
}

.tooltip-error.rc-tooltip-placement-bottomRight .rc-tooltip-content::after {
  display: none;
}

.rc-tooltip-placement-top .rc-tooltip-content::after {
  margin-left: -8px;
  top: unset;
  bottom: -1px;
  transform: rotate(-45deg);
}

.tooltip-dark .rc-tooltip-content::after,
.tooltip-dark .rc-tooltip-placement-top .rc-tooltip-content::after {
  border-color: transparent transparent #004285 #004285;
}

.tooltip-light .rc-tooltip-content::after,
.tooltip-light .rc-tooltip-placement-top .rc-tooltip-content::after {
  border-color: transparent transparent #ffffff #ffffff;
}

.tooltip-error .rc-tooltip-content::after,
.tooltip-error .rc-tooltip-placement-top .rc-tooltip-content::after {
  border-color: transparent transparent #ec3237 #ec3237;
}
